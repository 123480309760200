<template ref="search-container">
    <div class="o-search__parks-container" v-if="search.filtered_parks.length" ref="parksContainer">
        <ul v-if="search.can_user_select_parks" class="o-search__parks">
            <!-- "Select/Deselect All Parks" Option -->
            <li class="o-search__park" v-if="search.filtered_parks.length > 1">
                <input
                    type="checkbox"
                    :checked="areAllParksSelected"
                    @change="toggleAllParks"
                    id="select-all-parks"
                />
                <label for="select-all-parks">
                    <Squirrel />
                    {{ areAllParksSelected ? 'Deselect All Parks' : 'Select All Parks' }}
                </label>
            </li>

            <!-- Individual Park Options -->
            <li
                class="o-search__park"
                v-for="park in search.filtered_parks"
                :key="park.id"
            >
                <input
                    type="checkbox"
                    :checked="search.isParkSelected(park)"
                    @change="search.toggleSelection(park)"
                    :id="`park-${park.id}`"
                />
                <label :for="`park-${park.id}`">
                    <Squirrel />
                    {{ park.title }}
                </label>
            </li>
        </ul>
    </div>

    <div v-else>There are no parks matching your selection</div>
</template>


<script setup>
    import Squirrel from "~/assets/icons/squirrel.svg";

    const search = useSearchStore();

    // Check if all parks are selected
    const areAllParksSelected = computed(() => {
        return search.filtered_parks.length > 0 &&
            search.filtered_parks.every(park => search.isParkSelected(park));
    });

    // Select all parks
    const selectAllParks = () => {
        search.data.selected_parks = [...search.filtered_parks]; // Select all parks
    };

    // Deselect all parks
    const deselectAllParks = () => {
        search.data.selected_parks = []; // Clear selection
    };

    // Toggle between selecting and deselecting all parks
    const toggleAllParks = () => {
        if (areAllParksSelected.value) {
            deselectAllParks();
        } else {
            selectAllParks();
        }
    };
</script>
